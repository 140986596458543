import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Contact() {
    return (
      <div className="contact">
        <title>Contact Us</title>
        <Header></Header>
        <section className="contact-info">
          <p className="phone"><strong>Text: </strong>778-760-4081</p>
          <p className="email"><strong>Email: </strong>tutoringvernon@gmail.com</p>
          <p className="hours"><strong>Tutoring Hours: </strong></p>
          <ul>
            <li>Friday: 11am - 4pm</li>
            <li>Saturday: 11am - 4pm</li>
            <li>Sunday: 11am - 4pm</li>
          </ul>
          <p className="hour-note">*Please note that these hours may be subject to change each semester</p>
          <p className="last-line">Please ensure to include which service you would like to book as well as the preferred time(s) of day for tutoring. We hope to hear from you soon!</p>
        </section>
        <Footer/>
      </div>
    );
  }