import React from "react";
import { useNavigate } from "react-router-dom";

function BookNowButton() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/contact");
    }

    return (
        <button className="book-now-button" onClick={handleClick}>
          {"Book Now"}
        </button>
      );
}

export default BookNowButton;