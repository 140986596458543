import React from "react";
import BookNowButton from "./BookNowButton";

export default function SixHourPackage() {
    return (
      <div className="six-hour-package">
        <h1>6 Hour Package</h1>
        <p>Book six hours of tutoring in advance! Perfect for some extra assistance with multiple course topics or preparation for a big exam.</p>
        <h2>Price (In-Person): $132</h2>
        <h2>Price (Online): $102</h2>
        <BookNowButton></BookNowButton>
      </div>
    );
  }