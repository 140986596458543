import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Math1 from "../images/math.jpg";
import Math2 from "../images/math2.jpg";
import RemoteTutoring from '../components/OnlineTutoring';
import InPersonTutoring from '../components/InPersonTutoring';
import Discount from '../components/Discount';

export default function Home() {
    return (
      <div className="home">
        <title>Home Page</title>
        <Header></Header>
            <div>
              <section className="home-text">
                <h1>Our tutoring services can help any student improve at math!</h1>
                <p>We cover topics such as Algebra, Trigonometry, Pre-calculus, Calculus I, II, III, & more!</p>
                <p className="what-we-offer">What we offer: </p>
              </section>

                <section className="home-boxes">
                  <InPersonTutoring></InPersonTutoring>
                  <RemoteTutoring></RemoteTutoring>   
                  <Discount/>
                </section>

                {/* <section className="images">
                    <img className="math1" src={Math1}></img>
                    <img className="math2" src={Math2}></img>
                </section> */}
            </div>
        <Footer></Footer>
      </div>
    );
  }