import React from "react";
import { useNavigate } from "react-router-dom";

function LearnMoreButton() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/services");
    }

    return (
        <button className="learn-more-button" onClick={handleClick}>
          {"Learn More"}
        </button>
      );
}

export default LearnMoreButton;