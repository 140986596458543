import React from "react";
import BookNowButton from "./BookNowButton";

export default function OneSessionTwoHours() {
    return (
      <div className="one-session-two-hours">
        <h1>2 Hour Session</h1>
        <p>Two consecutive hours of personalized tutoring. Great if you need some extensive review for a test or help with a long assignment.</p>
        <h2>Price (In-Person): $45</h2>
        <h2>Price (Online): $35</h2>
        <BookNowButton></BookNowButton>
      </div>
    );
  }