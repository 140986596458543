import React from "react";
import LearnMoreButton from "./LearnMoreButton";

export default function Discount() {
    return (
      <div className>
        <h1>50% Off Your 1st Hour</h1>
        <p>We offer a 50% discount on your very first hour of tutoring! </p>

        <LearnMoreButton></LearnMoreButton>
      </div>
    );
  }