import React from "react";
import { useNavigate } from "react-router-dom";

function ContactUsButton() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/contact");
    }

    return (
        <button className="footer-button contact-us-button" onClick={handleClick}>
          {"Contact Us"}
        </button>
      );
}

export default ContactUsButton;