import React from "react";
import LearnMoreButton from "./LearnMoreButton";

export default function OnlineTutoring() {
    return (
      <div>
        <h1>Online Tutoring</h1>
        <p>Don't live in Vernon or find in-person sessions too inconvenient? We offer online tutoring to cover just that!</p>
        <LearnMoreButton></LearnMoreButton>
      </div>
    );
  }