import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Rheiley from "../images/rheiley.jpg";

export default function About() {
    return (
      <div className="about-us">
        <title>About Us</title>
        <Header></Header>
            <section className="about-text">
              <p>
                Our tutor, Rheiley, is a third year undergraduate student who is taking a major in Computer Science & a minor in Math & Statistics.
                His experience with difficult subjects that require a lot of math makes him a great tutor to students who need a bit of extra help.
                <br></br>
                <br></br>
                At Vernon Math Tutoring, we tutor students from high school all the way up to second year of university/college.
                Whether you are struggling with a difficult course, or need some help reviewing for a test, we are here to help you.
                <br></br>
                <br></br>
                Our lessons involve giving a brief overview of the material you are struggling with, 
                then moving on to practice based learning at your own pace until you begin to grasp the concepts. We believe that practice is the key to succeeding in math, however if
                you attempt a problem and don't know how to solve it, we will explain step-by-step on how to do it so that you will fully understand how to solve similar problems.
              </p>
      
            <img className="rheiley" src={Rheiley}></img>
            </section>
        <Footer></Footer>
      </div>
    );
  }