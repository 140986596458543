import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element = {<Home></Home>}></Route>
          <Route path = "/home" element = {<Home></Home>}></Route>
          <Route path = "/about" element = {<About></About>}></Route>
          <Route path = "/contact" element = {<Contact></Contact>}></Route>
          <Route path = "/services" element = {<Services></Services>}></Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
