import React from 'react';
import Navbar from './Navbar';
import '../styles.css';

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <h1>Vernon Math Tutoring</h1>
        <p>Unlock your potential in math with personalized tutoring</p>
        <Navbar/>
      </div>
    </header>
  );
}

export default Header;
