import React from "react";
import LearnMoreButton from "./LearnMoreButton";

export default function InPersonTutoring() {
    return (
      <div>
        <h1>In-Person Tutoring</h1>
        <p>Get personalized tutoring in your own home or in a public location within Vernon, BC!</p>

        <LearnMoreButton></LearnMoreButton>
      </div>
    );
  }