import React from "react";
import BookNowButton from "./BookNowButton";

export default function OneSession() {
    return (
      <div className="one-session">
        <h1>1 Hour Session</h1>
        <p>One hour of personalized tutoring. Perfect if you need a quick review before a test or help with a small assignment!</p>
        <h2>Price (In-Person): $25</h2>
        <h2>Price (Online): $20</h2>
        <BookNowButton></BookNowButton>
      </div>
    );
  }