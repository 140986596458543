import React from "react";
import { useNavigate } from "react-router-dom";

function ReviewUsOnGoogleButton() {
    const handleRedirect = () => {
        window.open('https://g.page/r/Cb8OMJXWkqQQEAI/review', '_blank'); 
      };

    return (
        <button className="footer-button review-button" onClick={handleRedirect}>
          {"Review Us on Google"}
        </button>
      );
}

export default ReviewUsOnGoogleButton;