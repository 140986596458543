import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import OneSession from '../components/OneSession';
import OneSessionTwoHours from '../components/OneSessionTwoHours';
import SixHourPackage from '../components/SixHourPackage';

export default function Services() {
    return (
      <div className="services">
        <title>Services</title>
        <Header></Header>
        <p className="services-note">Please note that the maximum hours booked per student on a single day cannot exceed <strong>2 hours</strong>.</p>
          <section className="services-boxes">
            <OneSession></OneSession>
            <OneSessionTwoHours></OneSessionTwoHours>
            <SixHourPackage></SixHourPackage>
          </section>
          <section className="payments">
            <p>Payments are charged after a session has finished. Packages can be paid for either per-session or all at once.</p>
            <p className="payment-types">We accept <strong>E-Transfer</strong>, <strong>Paypal</strong>, and <strong>Cash</strong>.</p>
          </section>
        <Footer></Footer>
      </div>
    );
  }